import React, { useState } from "react";
import Layout from "@components/Layout";
import BgImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Video from "@components/Video";
import { materialVideos } from "@utils/static-data";
import { useMediaQuery } from "react-responsive";

import css from "@assets/styles/scss/modules/bahan.module.scss";

const Bahan = () => {
  const img = useStaticQuery(graphql`
    query BahanQuery {
      bgBahanDesktop: file(
        relativePath: { eq: "bg/desktop/bg-hero-bahan.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1020, maxWidth: 2010, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgBahanMobile: file(relativePath: { eq: "bg/mobile/bg-hero-bahan.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 680, maxWidth: 350, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [activeTabs, setActiveTabs] = useState({ index: 0 });

  const bg = [
    img.bgBahanDesktop.childImageSharp.fluid,
    {
      ...img.bgBahanMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const renderMaterialDescList = () => {
    return materialVideos.map((data, idx) => (
      <div
        key={idx}
        className={`${css.listItem} ${
          idx === activeTabs.index ? css.active : ``
        }`}
        onClick={() => setActiveTabs({ index: idx })}
      >
        <span>{idx + 1}.&nbsp;</span>
        <span>Video {data.title}</span>
      </div>
    ));
  };

  const renderMaterialVideo = () => {
    let title = materialVideos[activeTabs.index].title;
    let link = materialVideos[activeTabs.index].link;
    let quality = materialVideos[activeTabs.index].quality;
    return (
      <>
        <p>{title.replace("Penjelasan ", "")}</p>
        <div className={css.videoContainer}>
          <Video
            link={link}
            quality={quality}
            imgStyle={{
              borderRadius: 0,
            }}
          />
        </div>
      </>
    );
  };

  return (
    <Layout noFooter>
      <BgImage fluid={bg} className={css.bahan}>
        <Row className={css.title}>
          <h1>
            <span style={{ color: "#f3611b" }}>Penjelasan</span>
            {isDesktop ? ` ` : <br />}
            Macam-macam Bahan
          </h1>
        </Row>
        <Row className={css.content}>
          <Col md={6} className={css.video}>
            {renderMaterialVideo()}
          </Col>
          <Col md={6} className={css.list}>
            {renderMaterialDescList()}
          </Col>
        </Row>
      </BgImage>
    </Layout>
  );
};

export default Bahan;
